import './App.css';
import React, { useEffect } from "react";

function App() {
  
  useEffect(() => {
   window.fbAsyncInit = function () {
      window.FB.init({
        cookie: true, 
        appId            : '332210865714701',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v21.0'
      });
      
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);


  const launchWhatsAppSignup = () => {
    if (typeof window.FB !== "undefined") {
      // window.fbq("trackCustom", "WhatsAppOnboardingStart", {
      //   appId: "332210865714701",
      //   feature: "whatsapp_embedded_signup",
      // });
      // if (typeof fbq === 'function') {
      //   fbq('trackCustom', 'WhatsAppOnboardingStart', {
      //       appId: '332210865714701',
      //       feature: 'whatsapp_embedded_signup'
      //   });
      // }
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            const code = response.authResponse.code;
            console.log(code, "code");
        
            console.log(response, "response");
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          scope: 'business_management, whatsapp_business_management, whatsapp_business_messaging',
          config_id: "8191468570971857",
          response_type: "code",
          override_default_response_type: true,
          extras: {
            sessionInfoVersion: 2,
            feature: 'whatsapp_embedded_signup',
                version: 1,
                setup: {
                    business_name: '',
                    phone_number: ''
                }
          },
        }
      );
    } else {
 
      setTimeout(launchWhatsAppSignup, 100);
    }
  };

  return (
    <>
    <div className="App">
    <h1>Hello from facebook whatsapp business login</h1>
    <hr/>
    <div className='card'>
      <button
                          onClick={launchWhatsAppSignup}
                          id="connect-with-facebook"
                          data-type="add"
                        >
                          <i className="bx bxl-facebook-circle"></i> Connect
                          with Facebook
                        </button>
  

      </div>
    </div>
    
    </>
  );
}

export default App;
